import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';
import logo from '../assets/new logo.jpg';
import { FaBars, FaTimes } from 'react-icons/fa'; // Optional icons for navbar toggle

const NavBar: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false); // Manage dropdown state

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/">
            <img className="nav-image" src={logo} alt="amanimoussa" />
          </Link>
        </li>
        <li>
          {/* Projects Dropdown */}
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            className="dropdown-toggle"
            onClick={toggleDropdown} // Toggle dropdown on click
          >
            PROJECTS
          </span>
          {dropdownOpen && (
            <ul className="dropdown-menu">
              <li>
                <Link to="/projects/1" onClick={closeDropdown}>The Morning Battle</Link>
              </li>
              <li>
                <Link to="/projects/2" onClick={closeDropdown}>City MRI</Link>
              </li>
              <li>
                <Link to="/projects/3" onClick={closeDropdown}>Displacement</Link>
              </li>
              <li>
                <Link to="/projects/4" onClick={closeDropdown}>The Bird Lady Transformation</Link>
              </li>
              <li>
                <Link to="/projects/5" onClick={closeDropdown}>The Fake Saint</Link>
              </li>
              <li>
                <Link to="/projects/6" onClick={closeDropdown}>Fragmented City</Link>
              </li>
              <li>
                <Link to="/projects/7" onClick={closeDropdown}>The City Top View</Link>
              </li>
              <li>
                <Link to="/projects/8" onClick={closeDropdown}>Crowd</Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link style={{ textDecoration: 'underline' }} to="/about">ABOUT</Link>
        </li>
        <li>
          <Link style={{ textDecoration: 'underline' }} to="/contact">CONTACT</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
