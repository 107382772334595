import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';
import logo from '../assets/Tap icon .png';

const ContactPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background-color: #f4e8d8;
  height: 100vh;
  box-sizing: border-box;
`;

const ContactInfo = styled.div`
  max-width: 50%;
`;

const Heading = styled.h1`
  color: #333;
  font-family: 'Georgia, serif';
  margin-bottom: 10px;
`;

const SubHeading = styled.h2`
  color: #555;
  font-family: 'Georgia, serif';
  margin-bottom: 20px;
`;

const Info = styled.p`
  color: #666;
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const ContactDetails = styled.div`
  margin-top: 20px;
`;

const DetailItem = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.1em;
  margin: 10px 0;
  color: #444;

  svg {
    margin-right: 10px;
  }
`;

const IconLink = styled.a`
  color: #444;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: #000;
  }
`;

const Illustration = styled.div`
  max-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

const ContactUs: React.FC = () => {
  return (
    <ContactPage>
      <ContactInfo>
        <SubHeading>Get in Touch</SubHeading>
        <Heading>Contact Me</Heading>
        <Info>
          I would love to hear from you! Whether you have questions about my art, upcoming exhibitions, or just want to say hello, feel free to reach out.
        </Info>
        <ContactDetails>
          <DetailItem>
            <IconLink href="https://www.instagram.com/amani.mousa/" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={24} />
              Instagram
            </IconLink>
          </DetailItem>
          <DetailItem>
            <FaEnvelope size={24} />
            <a href="mailto:amanialimousa@gmail.com">amanialimousa@gmail.com</a>
          </DetailItem>
        </ContactDetails>
      </ContactInfo>
      <Illustration>
        <img src={logo} alt="Illustration" />
      </Illustration>
    </ContactPage>
  );
};

export default ContactUs;
