import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import '../css/ProjectDetails.css';
import abb from '../assets/Cairo morning/ABB1D61E-AFE1-43FA-823A-FC9555372F9B.jpeg';
import cairoMorningMicroBus from '../assets/Cairo morning/Cairo_morning-microbus.jpg';
import fullSizeRender from '../assets/Cairo morning/FullSizeRender.jpg';
import cairoMorining1 from '../assets/Cairo morning/IMG_7959.jpg';
import cairoMorining2 from '../assets/Cairo morning/IMG_8504.jpg';
import cairoMorining3 from '../assets/Cairo morning/IMG_8548.jpg';
import sketch1 from '../assets/Cairo morning/Sketch1.jpeg';
import sketch2 from '../assets/Cairo morning/Sketch2.jpeg';
import boxingGlovesTree from '../assets/Cairo morning/The-boxing-gloves-tree.jpeg';
import cityMRI1 from '../assets/City MRI/IMG_1711.jpg';
import cityMRI2 from '../assets/City MRI/IMG_1910.jpg';
import cityMRI3 from '../assets/City MRI/IMG_1911.jpg';
import cityMRI4 from '../assets/City MRI/IMG_1920.jpg';
import cityMRI5 from '../assets/City MRI/IMG_3014.jpg';
import FullSizeRender from '../assets/Displacement/FullSizeRender.jpg';
import FullSizeRender1 from '../assets/Displacement/FullSizeRender(1).jpg';
import FullSizeRender2 from '../assets/Displacement/FullSizeRender(2).jpg';
import FullSizeRender3 from '../assets/Displacement/FullSizeRender(3).jpg';
import FullSizeRender4 from '../assets/Displacement/FullSizeRender(4).jpg';
import FullSizeRender5 from '../assets/Displacement/FullSizeRender(5).jpg';
import FullSizeRender6 from '../assets/Displacement/FullSizeRender(6).jpg';
import FullSizeRender7 from '../assets/Displacement/FullSizeRender(7).jpg';
import displacement1 from '../assets/Displacement/IMG_0744.jpg';
import displacement2 from '../assets/Displacement/IMG_1226.jpg';
import displacement3 from '../assets/Displacement/IMG_1227_2.jpg';
import displacement4 from '../assets/Displacement/IMG_1233.jpg';
import displacement5 from '../assets/Displacement/IMG_6319.jpg';
import displacement6 from '../assets/Displacement/IMG_6320.jpg';
import displacement7 from '../assets/Displacement/IMG_6321.jpg';
import displacement8 from '../assets/Displacement/IMG_6323.jpg';
import displacement9 from '../assets/Displacement/IMG_7284.jpg';
import birdLadyTransformation from '../assets/The bird lady transformation/IMG_2195.jpeg'
import fakeSaint1 from '../assets/The Fake Saint/fake saint.1.jpeg'
import fakeSaint2 from '../assets/The Fake Saint/IMG_3364.jpeg'
import fakeSaint3 from '../assets/The Fake Saint/IMG_3366.jpeg'
import fakeSaint4 from '../assets/The Fake Saint/IMG_4736.jpeg'
import fragmentedCity1 from '../assets/Fragmented City/17.25.jpeg'
import fragmentedCity2 from '../assets/Fragmented City/18250.jpeg'
import fragmentedCity3 from '../assets/Fragmented City/IMG_0425.jpeg'
interface ProjectData {
  title: string;
  description: string;
  images: string[];
  additionalDescription: string;
}

const ProjectDetails: React.FC = () => {
  useEffect(() => {
    document.title = 'Project - Amani Moussa';
  }, []);
  const { id } = useParams<{ id: string }>();

  const projectData: { [key: number]: ProjectData } = {
    1: {
      title: 'The Morning Battle (2024)',
      description: `while the word morning may bring to mind a certain sense of calm or quiet, as people
       wake up anticipating the day ahead and looking forward to their morning rituals (drinking coffee
        or having breakfast), for the residents of Cairo going about their day might entail a battle. 
        To be able to traverse the city, needs special qualifications, tactics and experience to manage 
        and manoeuvre the many difficulties of Cairo’s urban reality. The series presents some of those 
        challenges but also many of the ingenious survival skills Egyptian evolved and continue 
        to invent to go through their ‘morning battle’.`,
      images: [
        abb,
        cairoMorningMicroBus,
        fullSizeRender,
        cairoMorining1,
        cairoMorining2,
        cairoMorining3,
        sketch1,
        sketch2,
        boxingGlovesTree
      ],
      additionalDescription: '',
    },
    2: {
      title: 'City MRI (2021/2022-)',
      description: `In this project, I examine my surroundings the way MRI imaging might examine
       the human body. By zooming in on certain layers and elements, certain primary shapes start to emerge.
        This analytical breakdown of the city, creates “building blocks” that can appear random, but show the
         intricate composition that characterise the city as we now see it.`,
      images: [
        cityMRI1,
        cityMRI2,
        cityMRI3,
        cityMRI4,
        cityMRI5
      ],
      additionalDescription: 'Additional details about Project 2...',
    },
    3: {
      title: 'Displacement (2020 - )',
      description: `Through tracking the urban expansion of Cairo and Giza, and comparing the historical 
      maps of both areas over the last 40 years, a drastic change has occurred turning large green areas 
      (traditionally farmland) into grey concrete buildings and red brick constructions (mainly residential
       buildings and informal housing). A change that influenced the shape of the city and left a massive 
       impact on us, ”the residents of Cairo”.`,
      images: [
        FullSizeRender,FullSizeRender1,FullSizeRender2,FullSizeRender3,FullSizeRender4,
        FullSizeRender5,FullSizeRender6,FullSizeRender7,
        displacement1,displacement2,displacement3,displacement4,displacement5,displacement6,
        displacement7,displacement8,displacement9

      ],
      additionalDescription: '',
    },
    4: {
      title: 'The Bird Lady Transformation (2020 - 2021)',
      description: ` Hypothetically giving the bird lady (Egyptian statue dated to the Predynastic
      period, Naqada IIa c.3500-3400 BC. Excavated at the village of Ma'mariya in
      1907) the opportunity to pass through several realistic paths in our times,
      with all its demands and current pressures, and by making projections of
      personal experiences that I, or one of those around me, have been through.
      How will these modern experiences and ordeals affect her psychologically
      and physically?'`,
      images: [
        birdLadyTransformation,
      ],
      additionalDescription: '',
    },
    5: {
      title: 'The Fake Saint (2018 - 2020)',
      description: `The title "Saint" is usually associated with all those who embodied idealised
       virtues during their life on earth and those who acted with "heroism". The men in our middle 
       eastern societies, that support the individual freedom of a man from birth, and construct the
        gender roles, turn their status into a societal privilege. In such societies, it seems that
         societies are made for men, which give them a false sense of power through which they control
          others. This is manifested in parental authority, fraternal, and marital authority. He is the 
          ideal. While enjoying his various rights, he drops the burden of his duties,one after the other. 
          He becomes the only “divinely ordained” right.`,
      images: [
        fakeSaint1,fakeSaint2,fakeSaint3,fakeSaint4
      ],
      additionalDescription: '',
    },
    6: {
      title: 'Fragmented City (2017 - 2020)',
      description: `This series of drawings is inspired by my everyday life in Cairo. Spending hours 
      in Cairo's hectic and crowded traffic wasn't my choice but this gave me the chance to document
       my everyday observations about the way the city is expanding and stretching toward the east and 
       the west, doubling and tripling in size. This expansion across a network of vast highways gives 
       many residents a sense of being scattered or rather an ever increasingly fragmented city.`,
      images: [
        fragmentedCity1,fragmentedCity2,fragmentedCity3
      ],
      additionalDescription: '',
    },
    7: {
      title: 'The City Top View (2017)',
      description: `“Set location on map”,” share your location with me”, and “send your location”. 
      Using web mapping platforms on our mobile phones has been an action that we use on a daily basis,
       which is connected to my interest in how cities extend and expand to reach their final model of a city.
        Those maps with all their details showed me a different perspective on my work and understanding of 
        how cities are “constructed” or mapped.`,
      images: [
        
      ],
      additionalDescription: '',
    },
    8: {
      title: `Crowd (2013 - )`,
      description: `An ongoing project since 2013 from which subprojects have emerged. 
      The project used different mediums to look at the way urban densities and elements are composed.`,
      images: [
        'image1_url.jpg',
        'image2_url.jpg',
      ],
      additionalDescription: '',
    },
  };


  if (!id) {
    return <div>Project not found</div>;
  }

  const projectId = parseInt(id);
  const project = projectData[projectId];

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div>
      {/* <h1 className='titleAmani'>AMANI MOUSSA</h1> */}
      <h2 className='title'>{project.title}</h2>
      <p className='paragraph'>{project.description}</p>
      {project.images.map((image, index) => (
  <div className='project_img' key={index} style={{ marginBottom: '16px' }}>
    <img src={image} alt={`${project.title} ${index + 1}`} />
  </div>
))}
      {/* <Carousel showThumbs={false}
       infiniteLoop 
       useKeyboardArrows 
       autoPlay
       showArrows={true}
       showStatus={false}
       interval={10000}
       swipeable={true} 
       emulateTouch={true} 
       >
        {project.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`${project.title} image ${index + 1}`} />
          </div>
        ))}
      </Carousel> */}
      <p>{project.additionalDescription}</p>
    </div>
  );
};

export default ProjectDetails;
